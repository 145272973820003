import Header from './components/header.jsx';
import './style/App.css';
import Info from './components/info.jsx'

function App() {
  return (
    <div className="App">
     <Header></Header>
     
    </div>
  );
}

export default App;
